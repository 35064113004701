/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
const TagManager = require('react-gtm-module')

exports.onClientEntry = () => {
  if (typeof document !== 'undefined') {
    const TagManagerArgs = {
      gtmId: 'GTM-TF6VN34'
    }
    TagManager.initialize(TagManagerArgs)
    // var script1 = document.createElement('script')
    // script1.src = 'https://plugin.handtalk.me/web/latest/handtalk.min.js'
    // document.body.appendChild(script1)
  }
}
