module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"5f984603-91a8-49e7-9986-291b05daf27b","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFlNmJkMWFkLWZmMDItNGYxMi05NzBmLTJiZGM2NjMyNjk2MSIsInVzZXIiOiJiYTk2Mzk5Yi1mYzdkLTQyMDktOTEzMC0xMjJlNmM2MTBhMWMiLCJzaXRlIjoiNWY5ODQ2MDMtOTFhOC00OWU3LTk5ODYtMjkxYjA1ZGFmMjdiIiwiaWF0IjoxNjM4Mzg2OTU3fQ.2iQLa_WAFTYT7gBse1NtxCFDcjk4aigfPYy6GvZZTZA"},"prd":{"siteId":"5f984603-91a8-49e7-9986-291b05daf27b","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFlNmJkMWFkLWZmMDItNGYxMi05NzBmLTJiZGM2NjMyNjk2MSIsInVzZXIiOiJiYTk2Mzk5Yi1mYzdkLTQyMDktOTEzMC0xMjJlNmM2MTBhMWMiLCJzaXRlIjoiNWY5ODQ2MDMtOTFhOC00OWU3LTk5ODYtMjkxYjA1ZGFmMjdiIiwiaWF0IjoxNjM4Mzg2OTU3fQ.2iQLa_WAFTYT7gBse1NtxCFDcjk4aigfPYy6GvZZTZA"}}},
    },{
      plugin: require('../plugins/instagram-scrappy/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"IGQVJYSzkxSXBUUXJ5cmp5T3FXWExvSHFKZAVo4bG5xcHJMaXFSbTJGMTUxLTRuYW9ELUhTbHdEcEVZAMGtabkdmNWpPWU9vMUw0bGxrMlU0SFpvNFBSclNobkVxaUh2bDF0VndrX1k3U0pjem8wUDRDXwZDZD","limit":"20"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DIA Supermercado","short_name":"DIA","start_url":"/","background_color":"#DE2528","theme_color":"#DE2528","display":"minimal-ui","icon":"src/images/icon/fav-icon-180x180.png","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9ec52175eae8924b997fda45949b4df1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
